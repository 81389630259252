import FrontPageLayout from "components/layouts/FrontPageLayout";
import { useState, useEffect } from "react";
import { useCurrentJWT } from "lib/AuthUtils";
import { NextRouter, useRouter } from 'next/router';
import { DASHBOARD_HOME_PATH } from 'lib/Constants';


export default function Index(props) {

  const currentJWT:string = useCurrentJWT();
  const router:NextRouter= useRouter();

  useEffect(() => {

    if(currentJWT) {
      router.push(DASHBOARD_HOME_PATH);
    }

  }, [currentJWT]);

  return <FrontPageLayout {...props}></FrontPageLayout>;
}


