import { useState, useCallback, useRef, useReducer, useEffect } from "react";
import { Auth, Hub, Amplify } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import {
  useLoginMode,
  LOGIN_MODE_SIGNUP,
} from "components/contexts/LoginModeContext";
import { useFadeEffect } from "components/Util";
//@ts-ignore
import { slsFetch } from "@klaudsol/commons/lib/Client";
import { useRouter } from "next/router";
import styles from "styles/FrontPageLayout.module.scss";
import Link from "next/link";
import Image from "next/image";
import AppButtonSpinner from "components/klaudsolcms/AppButtonSpinner";
import { authReducer, initialState } from "components/reducers/authReducer";
import { INIT, ERROR, CLEANUP, SUCCESS } from "lib/actions";
import ForceChangePasswordForm from "components/elements/frontPage/ForceChangePasswordForm";
import { CognitoUserSession, CognitoIdToken } from "amazon-cognito-identity-js";
import { DASHBOARD_HOME_PATH } from 'lib/Constants';


const openGoogle = (evt:any):void => {
  (async () => {
    evt.preventDefault();
    const cred = await Auth.federatedSignIn({provider: CognitoHostedUIIdentityProvider.Google  });
  })(); 

};

const LoginForm = ({ className, logo }) => {
  const router = useRouter();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [state, dispatch] = useReducer(authReducer, initialState);
  const [session, setSession] = useState<CognitoUserSession | null>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [successMessage, setSuccessMessage] = useState<string>();


  const onSubmit = useCallback(
    (evt) => {
      evt.preventDefault();
      
      (async () => {
        try {
          const user = await Auth.signIn(email, password);
          router.push(DASHBOARD_HOME_PATH);
          setErrorMessage(null);
          setSuccessMessage("Welcome back! Logging you in...");
        } catch (error) {
          setErrorMessage("Incorrect email or password.");
          setSuccessMessage(null);
          console.log('error signing in', error);
        }
        
      })();
      
    },
    [email, password, router]
  );

  const errorBox = useRef();
  const successBox = useRef();
  const logoRef = useRef();
  const [showSocialLogin, setShowSocialLogin] = useState(true);

  //Revive face effect in the future. This is neat :)
  //useFadeEffect(errorBox, [state.submitted, state.isError]);
  //useFadeEffect(successBox, [state.submitted, state.isLoginSuccessful]);

  useEffect(() => {

    //Special consideration to MCI CMS
    if(window.location.host === 'test:3000' || window.location.host === 'mci-cms.stg.klaudsol.app') {
      setShowSocialLogin(false);
      if(logoRef.current) {
        //@ts-ignore
        logoRef.current.src = 'https://s3.amazonaws.com/mci-cms.stg.klaudsol.app/bcd8db73_MCI%20logo.png';
      }
    }

  }, []);

  return (
    <div className="container_login_form">
      {!state.isForceChangePassword && <>
      <div className="img_login_logo">
        <img
          ref={logoRef}
          src={logo?.link ?? '/logo-180x180.png'}
          alt="KlaudSol Logo"
          style={{width: "180px", height: "auto"}}

        />
      </div>

      <div className="form_login">

        {showSocialLogin && (
        <div className='text-align-center' style={{textAlign: "center"}}> 
          <img onClick={openGoogle} style={{cursor: "pointer"}} className="btn_google text-align-center" src="https://assets.klaudsol.com/timetrack/web_neutral_sq_ctn.svg" />
        </div>
        )}

        {errorMessage && (
          <div
            className="alert alert-danger px-3 pt-2 pb-2 mb-1 mt-3 text-align-center"
            style={{textAlign: "center"}}
          >
            {errorMessage}
          </div>
        )}
        {successMessage && (
        <div
          ref={successBox}
          className="alert alert-success px-3 pt-2 pb-2 mb-1 mt-3 text-align-center"
          style={{textAlign: "center"}}
        >
          {successMessage}
        </div>
           )}



        <label className="mb-2 mt-3">Email</label>
        <input
          type="email"
          className="input_login"
          autoComplete="email"
          onChange={(e) => setEmail(e.target.value)}
        />

        <label className="mb-2 mt-4">Password</label>
        <input
          type="password"
          className="input_login"
          autoComplete="email"
          onChange={(e) => !state.isLoading && setPassword(e.target.value)}
        />
      </div>
      <Link href="/admin/" passHref>
        <button className="btn_login" onClick={onSubmit}>
          {state.isLoading && <AppButtonSpinner />} Log in
        </button>
      </Link>
      </> 
      }
      {/*state.isForceChangePassword && <ForceChangePasswordForm email={email} pwd={password}/>*/}
    </div>
  );
};

export default LoginForm;
