export const SKELETON_FILLER = "_______________";
export const DEFAULT_SKELETON_ROW_COUNT = 3;
export const DEFAULT_SKELETON_ROW_TABLE_COUNT = 5;
export const defaultPageRender = 0;
export const maximumNumberOfPage = 10;
export const EntryValues = [10, 20, 30, 40, 50];
export const validImageTypes = "image/png, image/gif, image/jpeg, image/jfif,image/svg+xml";
export const validVideoTypes = "video/mp4";
export const validFileTypes = ".pdf, .doc, .docx";

// capabilities 
export const readContents = 'read_contents';
export const writeContents = 'write_contents';
export const readContentTypes = 'read_content_types';
export const writeContentTypes = 'write_content_types';
export const readSettings = 'read_setttings';
export const writeSettings = 'write_settings';
export const modifyLogo = 'modify_logo';
export const readUsers = 'read_users';
export const readPendingUsers = 'read_pending_users';
export const writeUsers = 'write_users';
export const readGroups = 'read_groups';
export const writeGroups= 'write_groups';
export const canLogIn = 'can_log_in';
export const canLogInToCms = 'can_log_in_to_cms';
export const canSignUp = 'can_sign_up';
export const editProfile = 'edit_profile';
export const approveUsers = 'approve_users';
export const rejectUsers = 'reject_users';
export const changeUserPassword = 'change_user_password';
export const deleteUsers = 'delete_users';
export const downloadCSV = 'download_csv';


// password creation
export const AUTO_PASSWORD = 'autogenerated';
export const CUSTOM_PASSWORD = 'custom'

export const DASHBOARD_HOME_PATH = '/admin';
export const LOGOUT_PATH = '/';

export const slugTooltipText = "Slugs are the URL-friendly names of your contents. You can access the contens in your API via their numerical ID (e.g. /api/articles/12) or their slug (e.g. /api/articles/my-first-blog-post)";

